import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

export default function Image({ name, imgStyle }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(
                  quality: 100
                  traceSVG: { color: "rgb(80, 153, 230)", threshold: 150 }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  originalName
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.allImageSharp.edges.find(edge => {
          return edge.node.fluid.originalName === name
        })

        if (!image) {
          console.log("No image")
          return null
        }

        return <Img fluid={image.node.fluid} />
      }}
    />
  )
}
