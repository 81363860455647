import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LanguageMenu from "./LanguageMenu"
import { useTranslation } from "react-i18next"

import logoFark from "../images/fark-logo.png"
import burgerIcon from "../images/Hamburger-icon.svg"

import "../styles/Header.styl"
import "../styles/DisplayMenu.styl"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  Header.defaultProps = {
    siteTitle: ``,
  }

  function handleClick() {
    const list = document.getElementById("list")
    list.classList.toggle("visible-animated")
  }

  return (
    <header>
      <img src={logoFark} alt="logo dual color FARCORP" className="logo" />
      <ul className="list" id="list">
        <li className="list-item">
          <Link to="/#who-we-are">{t("header.home")}</Link>
        </li>
        <li className="list-item">
          <Link to="/who-we-are/">{t("header.whoWeAre")}</Link>
        </li>
        <li className="list-item">
          {" "}
          <Link to="/our-activity/">{t("header.ourActivity")}</Link>
        </li>
        <li className="list-item">
          <Link to="/contact/">{t("header.contact")}</Link>
        </li>
        <li className="list-item">
          <LanguageMenu />
        </li>
      </ul>
      <i className="burger" id="burger" onClick={handleClick}>
        <img src={burgerIcon} className="burger__icon" alt="Menú burger" />
      </i>
    </header>
  )
}

export default Header
