import React, { useState } from "react"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  selectFocus: {
    "&:focus": {
      background: "transparent",
    },
  },
})

const LanguageMenu = props => {
  const { t, i18n } = useTranslation()

  const [values, setValues] = useState({
    language: "es",
  })

  function handleClick() {
    console.log(values.language)

    if (values.language === "es") {
      i18n.changeLanguage("en")
      setValues({ language: "en" })
    } else {
      i18n.changeLanguage("es")
      setValues({ language: "es" })
    }
  }

  return (
    <>
      <div id="lang" className="lang" onClick={handleClick}>
        {t("header.language")}
      </div>
    </>
  )
}

export default LanguageMenu
